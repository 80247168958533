import classNames from 'classnames';
import React from 'react';

import styles from './AppButton.module.scss';

export default function AppButton({
  children,
  isHeader,
  icon,
  cta,
  round,
  square,
  transparent,
  yellow,
  small,
  onClick,
}) {
  let icons = {
    arrow_right: (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.16602 10.5H15.8327M15.8327 10.5L9.99935 4.66663M15.8327 10.5L9.99935 16.3333"
          stroke="black"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    arrow_left: (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.834 10.5L4.16732 10.5M4.16732 10.5L10.0007 16.3334M4.16732 10.5L10.0007 4.66671"
          stroke="black"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),

    close: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 1L1 11M1 1L11 11"
          stroke="black"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),

    send: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.8334 1.66675L9.66675 10.8334M18.8334 1.66675L13.0001 18.3334L9.66675 10.8334M18.8334 1.66675L2.16675 7.50008L9.66675 10.8334"
          stroke="black"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),

    paper_plane: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.8337 1.16669L8.66699 10.3334M17.8337 1.16669L12.0003 17.8334L8.66699 10.3334M17.8337 1.16669L1.16699 7.00002L8.66699 10.3334"
          stroke="black"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  };

  return (
    <>
      {square && (
        <div className={styles.AppButton__Square__Wrapper}>
          <button
            onClick={onClick}
            className={classNames(styles.AppButtonSquare, {
              // eslint-disable-next-line
              [styles['AppButtonSquare--Transparent']]: transparent,
              // eslint-disable-next-line
              [styles['AppButtonSquare--IsHeader']]: isHeader,
              // eslint-disable-next-line
              [styles['AppButtonSquare--Yellow']]: yellow,
            })}>
            {children && <span>{children}</span>}
            {/* eslint-disable-next-line computed-property-spacing */}
            {icon && icons[icon]}
          </button>
        </div>
      )}

      {round && (
        <div className={styles.AppButton__Round__Wrapper}>
          <button
            onClick={onClick}
            className={classNames(styles.AppButton, {
              // eslint-disable-next-line
              [styles['AppButton--CTA']]: cta,
              // eslint-disable-next-line
              [styles['AppButtonRound--Yellow']]: yellow,
              // eslint-disable-next-line
              [styles['AppButtonRound--Small']]: small,
            })}>
            {children && <span>{children}</span>}
            {/* eslint-disable-next-line computed-property-spacing */}
            {icon && icons[icon]}
          </button>
        </div>
      )}
    </>
  );
}
